<template>
  <div>
    <vs-row
      vs-w="16"
      vs-type="flex"
      vs-align="flex-start"
      vs-justify="left"
      v-for="({ name, quantity }, index) in bundle"
      v-bind:key="index"
    >
      <vs-col vs-w="4">
        <p class="font-12">{{ name }}</p>
      </vs-col>
      <vs-col vs-w="8">
        <p class="font-12">{{ quantity }}</p>
      </vs-col>
    </vs-row>
    <!-- <vs-row vs-w="16" vs-type="flex" vs-align="flex-start" vs-justify="left">
            <vs-col vs-w="4">
                <p class="font-8" v-if="patient.isForPatient"> <span class="material-icons" style="font-size:12px;">
                        done
                    </span> </p>
                <p class="font-8" v-if="!patient.isForPatient"> <span class="material-icons" style="font-size:12px;">
                        radio_button_unchecked
                    </span></p>
            </vs-col>
            <vs-col vs-w="8">
                <p class="font-12">I confirm this script is for a patient</p>
            </vs-col>
        </vs-row>
        <vs-row vs-w="16" vs-type="flex" vs-align="flex-start" vs-justify="left">
            <vs-col vs-w="4">
                <p class="font-8" v-if="!patient.isForPatient"> <span class="material-icons" style="font-size:12px;">
                        done
                    </span> </p>
                <p class="font-8" v-if="patient.isForPatient"> <span class="material-icons" style="font-size:12px;">
                        radio_button_unchecked
                    </span></p>
            </vs-col>
            <vs-col vs-w="8">
                <p class="font-12">I confirm this script is for me, as my own patient</p>
            </vs-col>
        </vs-row> -->
  </div>
</template>

<script>
import moment from "moment";
export default {
  name: "BundleDetails",
  props: ["bundle"],
};
</script>
<style>
p.font-12 {
  font-size: 10px;
}

p.font-8 {
  font-size: 8px;
}
</style>
