<template>
  <div>
    <vs-row
      vs-w="16"
      vs-type="flex"
      vs-align="flex-start"
      vs-justify="left"
      v-for="({ name, quantity }, index) in items"
      v-bind:key="index"
    >
      <vs-col vs-w="9">
        <p class="font-12">{{ quantity }} x {{ name }}</p>
      </vs-col>
    </vs-row>
  </div>
</template>

<script>
export default {
  name: "BundledItemsDetails",
  props: ["items"],
};
</script>
<style>
p.font-12 {
  font-size: 12px;
}
</style>
