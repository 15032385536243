<template>
    <div>
        <div v-if="!isEditToggled">
            <vs-row vs-w="12" vs-type="flex" vs-align="flex-start" vs-justify="left"
                v-for="({ label, value }, index) in fields" v-bind:key="index">
                <vs-col vs-w="4">
                    <p class="font-12">{{ label }}</p>
                </vs-col>
                <vs-col vs-w="8">
                    <p class="font-12">{{ value }}</p>
                </vs-col>
            </vs-row>
            <vs-row vs-w="12" vs-type="flex" vs-align="flex-start" vs-justify="left">
                <vs-col vs-w="4">
                    <p class="font-8" v-if="patient.isForPatient"> <span class="material-icons" style="font-size:12px;">
                            done
                        </span> </p>
                    <p class="font-8" v-if="!patient.isForPatient"> <span class="material-icons" style="font-size:12px;">
                            radio_button_unchecked
                        </span></p>
                </vs-col>
                <vs-col vs-w="8">
                    <p class="font-12">I confirm this script is for a patient</p>
                </vs-col>
            </vs-row>
            <vs-row vs-w="12" vs-type="flex" vs-align="flex-start" vs-justify="left">
                <vs-col vs-w="4">
                    <p class="font-8" v-if="!patient.isForPatient"> <span class="material-icons" style="font-size:12px;">
                            done
                        </span> </p>
                    <p class="font-8" v-if="patient.isForPatient"> <span class="material-icons" style="font-size:12px;">
                            radio_button_unchecked
                        </span></p>
                </vs-col>
                <vs-col vs-w="8">
                    <p class="font-12">I confirm this script is for me, as my own patient</p>
                </vs-col>
            </vs-row>
        </div>
        <patient-form
            :forms="patient"
            :onSave="onSave"
            @insertPatient="updatePatientDetails"
            v-else
            directionForUse=""
        />
        <div>
            <vs-row vs-w="12" vs-type="flex" vs-align="flex-start" vs-justify="left" v-if="!isEditToggled">
                <vs-col vs-w="12">
                    <vs-button @click="handleEdit" type="line" class="p-1">Edit</vs-button>
                </vs-col>
            </vs-row>
            <vs-row vs-w="12" vs-type="flex" vs-align="flex-start" vs-justify="left" v-else>
                <vs-col vs-w="6">
                    <vs-button @click="updatePatientDetails" class="p-2">Update</vs-button>
                </vs-col>
                <vs-col vs-w="6">
                    <vs-button @click="handleCancel" type="border" class="p-2">Cancel</vs-button>
                </vs-col>
            </vs-row>
        </div>
    </div>
</template>

<script>
import moment from 'moment';
import PatientForm from './PatientForm.vue';
export default {
    name: "PatientDetails",
    props: ["patient", "isEditToggled"],
    components: {
        PatientForm
    },
    data() {
        return {
            fields: [],
            onSave: false,
        }
    },
    methods: {
        async updatePatientDetails() {
            this.$emit("handleUpdatePatient", this.patient);
            this.fields = [];
            this.prefillFields();
        },
        prefillFields() {
            const labels = {
                firstName: "First name",
                lastName: "Last name",
                contact: "Contact",
                address: "Address",
                suburb: "Suburb",
                state: "State",
                zipcode: "Postcode",
                dob: "Date of Birth"
            }

            if (process.env.VUE_APP_REGION !== "AU") labels['allergies'] = "Allergies"

            Object.keys(this.patient).map(k => {
                if (!["_id", "isForPatient"].includes(k)) {
                    this.fields.push({
                        label: labels[k],
                        value: k === 'dob' ? moment(this.patient[k]).format("DD MMM YYYY") : this.patient[k]
                    })
                }
            });
        },
        handleCancel() {
            this.$emit("changeEditToggle", false);
        },
        handleEdit() {
            this.$emit("changeEditToggle", true);
        }
    },
    created() {
        this.prefillFields();
    }
}
</script>
<style>
p.font-12 {
    font-size: 10px;
}

p.font-8 {
    font-size: 8px;
}
</style>