<template>
    <div>
        <form autocomplete="off" @submit.prevent class="py-5">
            <h5 class="py-2" style="font-size:14px;">Patient details</h5>
            <p class="font-12"><span>Please fill in your patient details. If you are the patient, enter your
                    details.</span></p>
            <vs-row vs-w="8">
                <vs-col vs-w="24" v-for="({ name, label, rule }) in inputs" v-bind:key="name">
                    <vs-input :success="!errors.first(rule)" val-icon-success="done" val-icon-danger="error"
                        :danger="errors.first(rule) ? true : false" :name="name" :data-vv-as="label" :label="label"
                        class="w-full custom-field" :danger-text="errors.first(rule)" v-model="forms[name]"
                        data-vv-scope="patient" v-validate="'required'" />
                </vs-col>
                <vs-col vs-w="24">
                    <div class="custom-field">
                        <label>Date of Birth</label>
                        <datepicker format="dd/MM/yyyy" :minimumView="'day'" :maximumView="'year'"
                        :initialView="'year'" v-model="forms.dob" :input-class="{
                            'is-true':
                                !errors.has('patient.dob') && forms.dob,
                            'is-danger': errors.has('patient.dob'),
                        }">
                            <template slot="afterDateInput">
                                <span v-if="forms.dob" class="
                    bg-color-done
                    input-icon-validate
                    vs-input--icon-validate
                  ">
                                    <i valiconpack="material-icons"
                                        class="vs-icon notranslate icon-scale material-icons null">done</i>
                                </span>
                                <span v-if="errors.has('patient.dob')" class="
                    bg-color-error
                    input-icon-validate vs-input--icon-validate
                  ">
                                    <i valiconpack="material-icons"
                                        class="vs-icon notranslate icon-scale material-icons null">error</i>
                                </span>
                            </template>
                        </datepicker>
                        <span class="text-danger" style="font-size: 9px;padding-left:5px;">
                            {{ errors.first("patient.dob") }}
                        </span>
                        <input type="hidden" data-vv-as="Date of Birth" data-vv-validate-on="change" name="dob"
                            v-validate="'required'" v-model="forms.dob" data-vv-scope="patient" />
                    </div>
                </vs-col>
                <!-- <vs-col vs-w="24" v-if="isUSRegion">
                  <h3 style="font-size: 16px" class="text-grey">Direction for use: {{ directionForUse }}</h3>
                </vs-col> -->
                <vs-col vs-w="24" class="mt-4">
                    <h5 class="py-2" style="font-size:14px;">Please select an option below before adding to cart</h5>
                </vs-col>
                <vs-col vs-w="24" class="font-12 py-3">
                    <vs-radio v-model="forms.isForPatient" :vs-value="true" vs-name="isForPatient">I confirm this script
                        is
                        for a patient
                    </vs-radio>
                </vs-col>
                <vs-col vs-w="24" class="font-12">
                    <vs-radio v-model="forms.isForPatient" :vs-value="false" vs-name="isForPatient">I confirm this
                        script
                        is for me, as my own
                        patient</vs-radio>
                </vs-col>
            </vs-row>
        </form>
    </div>
</template>


<script>
import Datepicker from "vuejs-datepicker";
export default {
    components: {
        Datepicker
    },
    name: "PatientForm",
    props: ["forms", "onSave", "insertPatient", "directionForUse"],
    data() {
        return {
            inputs: [{
                label: "First Name",
                name: "firstName",
                rule: "patient.firstName"
            }, {
                label: "Last Name",
                name: "lastName",
                rule: "patient.lastName"
            }, {
                label: "Contact #",
                name: "contact",
                rule: "patient.contact"
            }, {
                label: "Address",
                name: "address",
                rule: "patient.address"
            }, {
                label: "City / suburb",
                name: "suburb",
                rule: "patient.suburb"
            }, {
                label: "State",
                name: "state",
                rule: "patient.state"
            }, {
                label: "Zip code",
                name: "zipcode",
                rule: "patient.zipcode"
            }]
        }
    },
    watch: {
        onSave: async function (val) {
            if (val) {
                const isValid = await this.$validator.validateAll("patient");
                if (isValid) {
                    this.$emit("insertPatient")
                }
            }
        },
    },
    computed: {
      isUSRegion: () => process.env.VUE_APP_REGION !== "AU"
    },
    created() {
      if (process.env.VUE_APP_REGION !== "AU") {
          this.inputs.push(
              {
                  label: "Allergies",
                  name: "allergies",
                  rule: "patient.allergies"
              }
          )
      }
    }
}
</script>
<style>
.custom-field label {
    display: block;
    padding: 5px 0 !important;
    font-size: 12px;
}

.bg-color-error {
    background: rgba(234, 84, 85, 0.14);
    color: rgba(234, 84, 85, 1);
}

.bg-color-done {
    background: rgba(40, 199, 111, 0.14);
    color: rgba(40, 199, 111, 1);
}

.is-danger {
    border: solid 1px rgba(234, 84, 85, 1) !important;
}

.is-true {
    border: solid 1px rgba(40, 199, 111, 1) !important;
}

.font-12 span {
    font-size: 12px !important;
}
</style>